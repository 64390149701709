import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Connector, Evse, Location } from '../types';
import { loadStripe } from '@stripe/stripe-js';
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import {
  Box,
  Typography,
  IconButton,
  Card,
  CardContent,
  List,
  Chip,
  Paper,
  Drawer,
  styled,
  ButtonGroup,
  Button as MuiButton,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BoltIcon from '@mui/icons-material/Bolt';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { styled as muiStyled } from '@mui/material/styles';

interface ChargingStationDetailProps {
  station: Location;
}

// Initialize Stripe (put this outside the component)

// Create a Payment Sheet component
const PaymentSheet: React.FC<{ 
  station: Location; 
  onSuccess: (response: { id: string }) => void; 
  onCancel: () => void;
  selectedConnector: Connector;
  selectedCharger: Evse | null;
}> = ({ station, onSuccess, onCancel, selectedConnector, selectedCharger }) => {
  const [clientSecret, setClientSecret] = useState<string>();
  const [accountId, setAccountId] = useState<string>();
  const [setupIntentId, setSetupIntentId] = useState<string>();

  useEffect(() => {
    // Fetch client secret when component mounts
    fetch('https://2ufjtpakn2.execute-api.us-east-1.amazonaws.com/prod/api/authorize-public-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        siteId: station.id
      })
    })
      .then(res => res.json())
      .then(data => {
        setClientSecret(data.clientSecret);
        setAccountId(data.id);
        setSetupIntentId(data.intentId);
      });
  }, [station.id]);

  if (!clientSecret || !accountId) return null;

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? 'pk_live_51JKB4QCbK1IBy8Wwto7yS1lWKGOSnLrlbIqRKywSyulikbnZHqaKngERmcYAH1zdaTQOUAJIcchhsZoo3WVKzxfD00AjWyb58U',
    {
      stripeAccount: accountId
    }
  );
  return (
    <Elements stripe={stripePromise} options={{ 
      clientSecret,
      appearance: {
        theme: 'night',
        variables: {
          colorPrimary: '#2196F3', // Your primary color
          colorBackground: '#1E1E1E', // Dark background
          colorText: '#FFFFFF',
          colorDanger: '#FF5252',
          fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
          borderRadius: '8px',
          spacingUnit: '4px',
        },
        rules: {
          '.Input': {
            border: '1px solid #424242',
            padding: '12px',
          },
          '.Input:focus': {
            border: '1px solid #2196F3',
          },
          '.Label': {
            color: '#9E9E9E',
          }
        }
      },
    }}>
      <PaymentForm 
        onSuccess={onSuccess} 
        onCancel={onCancel}
        selectedConnector={selectedConnector}
        selectedCharger={selectedCharger}
        setupIntentId={setupIntentId || ''}
        accountId={accountId || ''}
        station={station}
      />
    </Elements>
  );
};

// Add the styled components before the ChargingStationDetail component
const CardContainer = muiStyled(Box)(({ theme }) => ({
  padding: '12px',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '8px',
  marginBottom: '24px',
}));

const ErrorMessage = muiStyled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  margin: '12px 0',
  fontSize: '14px',
}));

// Separate the form into its own component
const PaymentForm: React.FC<{ 
  onSuccess: (response: { id: string }) => void; 
  onCancel: () => void;
  selectedConnector: Connector;
  selectedCharger: Evse | null;
  setupIntentId: string;
  station: Location;
  accountId: string;
}> = ({ onSuccess, onCancel, selectedConnector, selectedCharger, setupIntentId, accountId, station }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string>();
  const [isReady, setIsReady] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    setIsProcessing(true);
    setError(undefined);

    try {
      // 1. Save the payment method with SetupIntent
      const result = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/charging-session`,
          payment_method_data: { billing_details: {} }
        },
        redirect: 'if_required'
      });

      if (result.error) {
        setError(result.error.message);
        return;
      }

      // 2. Start session - backend will create Customer and attach payment method
      const sessionResponse = await fetch('https://2ufjtpakn2.execute-api.us-east-1.amazonaws.com/prod/api/ocpi/cpo/2.2.1/commands/start_session', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          setupIntentId: result.setupIntent.id,
          paymentMethodId: result.setupIntent.payment_method,
          connectorId: selectedConnector.connectorId,
          chargerId: selectedCharger?.evseId,
          authTag: selectedCharger?.evseId + '-PUB',
        })
      });

      if (!sessionResponse.ok) {
        throw new Error('Failed to start charging session');
      }

      const sessionData = await sessionResponse.json();
      onSuccess(sessionData);
    } catch (err) {
      setError(typeof err === 'string' ? err : 'Failed to start charging session');
    }
  };

  const handleReady = () => {
    setIsReady(true);
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardContainer>
        <PaymentElement onReady={handleReady} />
      </CardContainer>

      {error && (
        <ErrorMessage variant="body2">
          {error}
        </ErrorMessage>
      )}

      <ButtonGroup sx={{ mt: 3, gap: 1.5, width: '100%' }}>
        <MuiButton variant="outlined" onClick={onCancel} disabled={isProcessing}>
          Cancel
        </MuiButton>
        <MuiButton variant="contained" type="submit" disabled={!stripe || !elements || isProcessing}>
          {isProcessing ? 'Starting Session...' : 'Start Charging'}
        </MuiButton>
      </ButtonGroup>
    </form>
  );
};

const ChargingStationDetail: React.FC<ChargingStationDetailProps> = ({ station }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const evseIdParam = searchParams.get('evse');
  const [selectedEvse, setSelectedEvse] = useState<Evse | null>(null);
  const [selectedConnector, setSelectedConnector] = useState<Connector | null>(null);

  const filteredEvses = evseIdParam 
    ? station.evses?.filter(evse => evse.evseId === evseIdParam)
    : station.evses;

  const handleSuccess = (response: { id: string }) => {
    localStorage.setItem('activeChargingSession', JSON.stringify({
      id: response.id,
      stationName: station.name
    }));
    
    const sessionUrl = `/charging-session/${response.id}`;
    navigate(sessionUrl);
  };

  return (
    <Box sx={{ minHeight: '100vh', bgcolor: 'background.default', color: 'text.primary' }}>
      <Box sx={{ p: 2, display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={() => navigate(-1)} edge="start" color="inherit">
          <ArrowBackIcon />
        </IconButton>
      </Box>

      <Box sx={{ px: 2 }}>
        <Typography 
          variant="body2" 
          color="text.secondary"
          sx={{ fontSize: '0.875rem', fontWeight: 400, mb: 1 }}
        >
          {station.operator.name}
        </Typography>
        
        <Typography 
          variant="h1"
          sx={{ 
            fontSize: '2.5rem', 
            fontWeight: 500,
            mb: 1 
          }}
        >
          {station.name}
        </Typography>
        
        <Typography 
          variant="body1" 
          color="text.secondary"
          sx={{ 
            fontSize: '1.25rem',
            fontWeight: 400,
            mb: 2 
          }}
        >
          {station.address}
        </Typography>
        
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <AccessTimeIcon sx={{ color: 'text.secondary' }} />
            <Typography sx={{ 
              fontSize: '1.125rem',
              fontWeight: 500
            }}>
              Open 24 hours
            </Typography>
          </Box>

          <Typography sx={{ 
            fontSize: '1.25rem',
            fontWeight: 500
          }}>
            Current Rate: ${station.currentPrice?.price?.toFixed(2) || '0.00'} / kWh
          </Typography>
        </Box>

        <List sx={{ mb: 3 }}>
          {(filteredEvses ?? []).map((evse) => (
            <Card key={evse.evseId} sx={{ 
              mb: 2,
              borderRadius: 2,
              boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)'
            }}>
              <CardContent>
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between', 
                  alignItems: 'center',
                  mb: 2,
                  gap: 2
                }}>
                  <Typography 
                    variant="h6" 
                    sx={{ 
                      fontSize: '1.5rem', 
                      fontWeight: 500,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      flexGrow: 1
                    }}
                  >
                    {evse.evseName}
                  </Typography>
                  <Chip
                    label={evse.status === 'AVAILABLE' ? 'Available' : 'Busy'}
                    color={evse.status === 'AVAILABLE' ? 'success' : 'default'}
                    sx={{ 
                      borderRadius: '16px',
                      px: 2,
                      fontSize: '1rem',
                      flexShrink: 0
                    }}
                  />
                </Box>
                
                <List disablePadding>
                  {(evse.connectors ?? []).map((connector) => (
                    <Paper
                      key={connector.connectorId}
                      sx={{ 
                        p: 2,
                        mb: 1.5,
                        borderRadius: 2,
                        bgcolor: 'background.paper'
                      }}
                      onClick={() => {
                        setSelectedConnector(connector);
                        setSelectedEvse(evse);
                      }}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Typography variant="h6">
                            {connector.connectorName || `Connector ${connector.connectorId}`}
                          </Typography>
                          <Typography 
                            sx={{ 
                              color: connector.status === 'AVAILABLE' ? 'success.main' : 'text.secondary'
                            }}
                          >
                            {connector.status === 'AVAILABLE' ? 'Available' : 'Busy'}
                          </Typography>
                        </Box>
                        
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography color="text.secondary">
                              {connector.standard || 'UNKNOWN'}
                            </Typography>
                            <Typography color="text.secondary">
                              {connector.maxElectricPower}kW
                            </Typography>
                          </Box>
                          
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, color: 'success.main' }}>
                            {[...Array(connector.maxElectricPower >= 75 ? 3 : connector.maxElectricPower >= 50 ? 2 : 1)].map((_, i) => (
                              <BoltIcon key={i} sx={{ fontSize: '1.2rem', color: 'success.main' }} />
                            ))}
                          </Box>
                        </Box>
                      </Box>
                    </Paper>
                  ))}
                </List>
              </CardContent>
            </Card>
          ))}
        </List>
      </Box>

      <Drawer
        anchor="bottom"
        open={Boolean(selectedConnector)}
        onClose={() => {
          setSelectedConnector(null);
          setSelectedEvse(null);
        }}
      >
        <Box sx={{ p: 3 }}>
          <Typography variant="h6" gutterBottom>
            {selectedConnector?.connectorName}
          </Typography>
          <Typography variant="body2" gutterBottom>
            Connector: {selectedConnector?.connectorName || selectedConnector?.connectorId}
          </Typography>
          {selectedConnector && (
            <PaymentSheet 
              station={station}
              selectedConnector={selectedConnector}
              selectedCharger={selectedEvse}
              onSuccess={handleSuccess}
              onCancel={() => setSelectedConnector(null)}
            />
          )}
        </Box>
      </Drawer>
    </Box>
  );
};

export default ChargingStationDetail;